import logo from './logo.svg';
import './App.scss';
import React from 'react';
import CreditPerson from './components/creditperson/creditperson';
import Gallery from './components/gallery/gallery';
import ReCAPTCHA from "react-google-recaptcha";
import ImageQualityManager from "./components/ImageQualityManager/ImageQualityManager";
 
class App extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      gallery: [
        'images/gallery/1',
        'images/gallery/2',
        'images/gallery/3',
        'images/gallery/4',
        'images/gallery/5',
      ],
        isMenuOpened: false,
        mailValue: "",
        recapStatus: false,
        alert: "",
        portfolioMouseOver:false,
    }
    this.scrollToView = this.scrollToView.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.onChange = this.onChange.bind(this);
    this.insertMailValue = this.insertMailValue.bind(this);
    this.clearMailValue = this.clearMailValue.bind(this);
    this.sendMailValue = this.sendMailValue.bind(this);

    this.scrollPortfolio = this.scrollPortfolio.bind(this);
    this.sideScroll = this.sideScroll.bind(this);
    this.observeEntryOnPortfolio = this.observeEntryOnPortfolio.bind(this);
  }

  onChange = (value) => {
    this.setState({recapStatus: value})
  }

  insertMailValue = (value) => {
    this.setState({mailValue: value.target.value})
  }

  clearMailValue = () => {
    this.setState({mailValue: "", alert: ""});
  }

  sendMailValue = (value) => {
    if (this.state.recapStatus) {
      
      if(this.state.mailValue.includes("@")) {
        let self = this;
        const axios = require('axios');
        axios.get('https://tomaszjagoda.com/newsletter/?email=' + this.state.mailValue)
        .then(function (response) {
          //self.setState({alert: "Your mail was added."});
          if (response.data.status === 'exist' || response.data.status === 'added' ) {
            self.setState({alert: "Your email was added"});
          } else {
            self.setState({alert: "Your email wasn't added"});
          }

        })
        .catch(function (error) {
          self.setState({alert: "Something wen't wrong"});
        });
      } else {
        this.setState({alert: "Mail isn't correct"});
      }
    } else {
      this.setState({alert: "RE-CAPATCHA isn't correct"});
    }
  }
  
  scrollToView = (id) => {
    let anchor = document.querySelector("#" + id);
    anchor.scrollIntoView();
    this.setState({isMenuOpened: false});
  }

  onChange(value) {
    console.log("Captcha value:", value);
  }

  openMenu = () => {
    this.setState({isMenuOpened: !this.state.isMenuOpened});
  }

  onChange(value) {
    console.log("Captcha value:", value);
  
  }


  observeEntryOnPortfolio = () => {
    const sectionOne = document.querySelector('.recording-session');
    const options = {
      root: null, 
      threshold: 0,
      rootMargin: "-50%",
    };
    let callback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting === true) {
          this.setState({portfolioMouseOver: true});
        } else {
          this.setState({portfolioMouseOver: false});
        }
      });
    }
    const observer = new IntersectionObserver(callback,options);
    observer.observe(sectionOne);
  };

  scrollPortfolio = (direction) => {
    if (direction === 'left') {
      //document.getElementById('work').scrollLeft += document.getElementById('work').offsetWidth / 2;
      let container = document.getElementById('gallery-container');
      this.sideScroll(container,'left',2,document.getElementById('gallery-container').offsetWidth / 2,10);
    }

    if (direction === 'right') {
      let container = document.getElementById('gallery-container');
      this.sideScroll(container,'right',2,document.getElementById('gallery-container').offsetWidth / 2,10);
      //document.getElementById('work').scrollLeft -= document.getElementById('work').offsetWidth / 2;
    }
  }
  

  sideScroll = (element,direction,speed,distance,step) => {
   let scrollAmount = 0;
    let slideTimer = setInterval(function(){
        if(direction === 'left'){
            element.scrollLeft -= step;
        } else {
            element.scrollLeft += step;
        }
        scrollAmount += step;
        if(scrollAmount >= distance){
            window.clearInterval(slideTimer);
        }
    }, speed);
} 

componentDidMount() {
  this.observeEntryOnPortfolio();
}
  render() {
    return (
      <div className="App">
        <div className="header">
          <div className="title">TOMASZ JAGODA</div>
          <div className={ this.state.isMenuOpened ? "icon-mobmenu icon-close" : "icon-mobmenu"} onClick={() => this.openMenu()}></div>
          <div className={ this.state.isMenuOpened ? "menu" : "menu hidden"}>
            <a href="#home" onClick={() => this.scrollToView('home')}>Home</a>
            <a href="#illusions" onClick={() => this.scrollToView('illusions')}>Illusions</a>
            <a href="#recording-session" onClick={() => this.scrollToView('recording-session')}>Recording Session</a>
            <a href="#contact" onClick={() => this.scrollToView('contact')}>Contact</a>
          </div>
        </div>
        <div id="home" className="card">
        <ImageQualityManager className="image-landing" name="landing" extension="jpg"/>
          <div className="socials">
              <a href="https://www.instagram.com/tomasz.jagoda/" target="_blank"><div className="icon image-instagram"/></a>
              <a href="https://open.spotify.com/artist/24WJ9AIGRLxZ7drH2J0TDW" target="_blank"><div className="icon image-spotify"/></a>
              <a href="https://www.youtube.com/channel/UCkFAiz1iLG9eqsZcOkiD57Q" target="_blank"><div className="icon image-youtube"/></a>
              <a href="https://www.facebook.com/tomaszjagoda.saxophone" target="_blank"><div className="icon image-facebook"/></a>
          </div>
          
        </div>
        <div id="illusions" className="card music">
          <div className="title">Illusions <strong>2021</strong></div>
          <ImageQualityManager className="image-illusions-cover" name="cover" extension="jpg"/>
          <div className="container">
            <div className="col">
              
              <div className="links">
                <a href="#music"><div className="image-spotify"/></a>
                <a href="#music"><div className="image-youtube"/></a>
              </div>


              <div className="spotify">
              <div className="title">Tracklist</div>
              <div dangerouslySetInnerHTML={{ __html: '<div style="left: 0; width: 100%; height: 100%; position: relative;"><iframe src="https://open.spotify.com/embed/album/5qyp7jx9dsqtXz1cAQSUnV" style="top: 0; left: 0; width: 100%; height: 100%; position: absolute; border: 0;" allowfullscreen allow="encrypted-media;"></iframe></div>' }} className="tracks"></div>
              </div>

              <div className="credits">
                    <div className="container">
                    <div className="col">
                      <div className="title">Band</div>
                      <CreditPerson position="double bass" name="Maciej Sadowski"></CreditPerson>
                      <CreditPerson position="piano" name="Aleksandra Mularczyk"></CreditPerson>
                      <CreditPerson position="drums" name="Jakub Grzywacz"></CreditPerson>
                    </div>
                    <div className="col">
                      <div className="title">Post Production</div>
                      <CreditPerson position="Mix/Mastering" name="Dave Darlington"></CreditPerson>
                      <CreditPerson position="Recording" name="Wojciech Noskowiak"></CreditPerson>
                      <CreditPerson position="Recording" name="Piotr Lukaszewski"></CreditPerson>
                    </div>
                    <div className="col">
                      <div className="title">Others</div>
                      <CreditPerson position="Featured Photo" name="Karolina Orzechowska"></CreditPerson>
                      <CreditPerson position="Consultation" name="Monika Kubicz"></CreditPerson>
                      <CreditPerson position="Consultation" name="Adrian Wysocki"></CreditPerson>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div id="recording-session" className="title recording-session">Illusions Recording Session<strong>2019</strong></div>
          <div dangerouslySetInnerHTML={{ __html: '<div style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: 56.25%;"><iframe src="https://www.youtube.com/embed/X9M6y7kU8Jo" style="top: 0; left: 0; width: 100%; height: 100%; position: absolute; border: 0;" allowfullscreen scrolling="no" allow="encrypted-media;"></iframe></div>' }} className="preview"></div>
          <p>Album was recorded at Custom34 Studio, Gdansk, Poland</p>
          <Gallery data={this.state.gallery}></Gallery>
            <div className="portfolio-navigation">
              <div className="right" onClick={() => this.scrollPortfolio('right')} /> 
              <div className="left" onClick={() => this.scrollPortfolio('left')} />
            </div>
          </div>
          <div id="contact" className="card footer">
              <div className="newsletter">
                <div className="title">MAILING LIST</div>
                <p>Sign up with your email address to receive news and updates.</p>

                {
                  this.state.alert.length > 0 ?
                    <div className="alert">
                      <p> {this.state.alert} </p>
                      <div className="btn" onClick={this.clearMailValue}>OK</div>
                    </div>
                  :
                    <div className="form">
                    <input type="text" className="input" value={this.state.mailValue} onChange={this.insertMailValue} placeholder="enter your email"></input>
                    <div className="btn" onClick={this.sendMailValue}>SEND</div>
                    {
                      this.state.mailValue.length > 0 ? 

                    <div className="recap">
                    <ReCAPTCHA
                        sitekey="6LcojnQbAAAAAD7bU1D5WF3uD156q4RUENZRBl80"
                        onChange={this.onChange}
                      />
                    </div>
                    : ''
                    }
                  </div>
                }
              </div>

              <div className="end">
                <div className="location">Sopot, Poland 2021</div>
                <div className="address"><strong>TOMASZJAGODA.COM</strong></div>
                <div className="address">contact@tomaszjagoda.com</div>
              </div>
            </div>
      </div>
    );
  }
}

export default App;


// <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://player.vimeo.com/video/259411563" class="vimeo" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe><p><a href="https://vimeo.com/259411563">A Love Letter to Winter</a> from <a href="https://vimeo.com/thenorthface">The North Face</a> on <a href="https://vimeo.com">Vimeo</a>.</p>' }} className="preview"></div>
//<div dangerouslySetInnerHTML={{ __html: '<iframe src="https://open.spotify.com/embed/album/7klgB8E12bA0hSCJAEJsUs" class="spotify" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>' }} className="tracks"></div>

/*
<div className="col">
<CreditPerson position="piano" name="Aleksandra Mularczyk"></CreditPerson>
<CreditPerson position="mix/mastering" name="Dave Darlington"></CreditPerson>
<CreditPerson position="consultations" name="Monika Kubicz"></CreditPerson>
<CreditPerson position="consultations" name="Adrian Wysocki"></CreditPerson>
<CreditPerson position="recording engineer" name="Wojciech Noskowiak"></CreditPerson>
</div>
*/


/*

          <div className="track-list">
            Nothing Inside
            If I Could Tell You
            No Doubt
            A Place We Know
            Horizons
            Illusions

          </div>
          */
         // <div className="btn-enroll">Scroll Down</div>