import React from 'react';
class CreditPerson extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div className="person">
            <div className="position">
              { this.props.position }
            </div>
            <div className="name">
              { this.props.name }
            </div>
          </div>
        );
    }
}

export default CreditPerson;