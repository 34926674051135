
import React from 'react';
class Preview extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.isPreviewOpened) {
            return(
                <div className="preview-container">
                    <div className="icon-cancel" onClick={this.props.callBackAction}/>
                    <img src={this.props.data.url}/>
                </div>
            );
        } else {
            return ("");
        }
    }
}

export default Preview;