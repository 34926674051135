import React from 'react';
import Preview from '../preview/Preview';
class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPreviewOpened: false,
            previewData: {},
            index: 0
        }
        this.openPreview = this.openPreview.bind(this);
        this.closePreview = this.closePreview.bind(this);
        this.setDeviceSize = this.setDeviceSize.bind(this);
    }
    openPreview = (i) => {
        this.setState({isPreviewOpened: true, index: i});
        //,previewData: {name: data.name, url: data.url}}
    }

    closePreview = () => {
    this.setState({isPreviewOpened: false});
    }

    setDeviceSize = () => {
    if(window.screen.availWidth > 1920){
        this.setState({deviceSize:"4k"});
      };

      if(window.screen.availWidth > 1024){
        this.setState({deviceSize:"hd"});
      };

      if(window.screen.availWidth <= 1024 && window.screen.availWidth >= 600){
        this.setState({deviceSize:"tablet"});
      };

      if(window.screen.availWidth < 600){
        this.setState({deviceSize:"mobile"});
      };
    }
    componentDidMount() {
       this.setDeviceSize();
    }
    render() {
        let preview = '';
        if (this.state.isPreviewOpened) {
            preview = <Preview data={{url: this.props.data[this.state.index] + '.jpg' , name:'Prev'}} isPreviewOpened={this.state.isPreviewOpened} callBackAction={this.closePreview}></Preview>;
        }
        return(
            <div id="gallery-container" className="gallery">
                {preview}
            {
                this.props.data.map((item, i) => {
                    return(
                        <div className="thumbnail" onClick={() => this.openPreview(i)}>
                        <img src={item + '_thumb.jpg'}/>
                    </div>
                    );
                })
            }
        </div>
        );
    }
}

export default Gallery;