import React from 'react';
class ImageQualityManager extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            deviceSize: "",
        }
        this.setDeviceSize = this.setDeviceSize.bind(this);
    }
    setDeviceSize = () => {
        if(window.screen.availWidth > 1920){
            this.setState({deviceSize:"4k"});
          };
    
          if(window.screen.availWidth > 1024){
            this.setState({deviceSize:"hd"});
          };
    
          if(window.screen.availWidth <= 1024 && window.screen.availWidth >= 600){
            this.setState({deviceSize:"tablet"});
          };
    
          if(window.screen.availWidth < 600){
            this.setState({deviceSize:"mobile"});
          };
      }
      componentDidMount() {
           this.setDeviceSize();
      }
    render() {
      if(this.props.name === 'landing') {
        return(<div className={this.props.className + '_' + this.state.deviceSize} />);
      } else if (this.props.name === 'cover') {
        return(<div className={this.props.className + '_' + this.state.deviceSize} />);
      } 
      else {
        return(<img className={this.props.className} src={"/" + this.props.name + "_" + this.state.deviceSize + '.' + this.props.extension}/>);
      }
    }
}

export default ImageQualityManager;